<template>
    <div class="disclaimer">
        Google Play and the Google Play logo are trademarks of Google LLC.<br />
        Apple and the Apple logo are trademarks of Apple Inc., registered in the US and other countries / regions. App Store is a service mark of Apple Inc.
    </div>
    <br /><br />
    <div class="attribution">
        <a target="_blank" href="https://www.flaticon.com/free-icons/gourd" title="gourd icons">Gourd icons created by Konkapp - Flaticon</a>
    </div>
</template>

<style scoped>
.disclaimer {
    font-size: 12px;
}

.attribution {
    font-size: 7px;
}
</style>