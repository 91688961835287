<template>
  <div class="two-app-container">

    <div class="app-container">
      <a href="https://dices.sicsic.xyz" target="_blank">
        <div>
          <div class="app-icon big-small"></div><br />
          <div>可以骰骰</div>
        </div>
      </a>
      <br />
      <br />
      <div class="category-container">
        <div class="cat-title">手機</div>
        <a href="https://apps.apple.com/us/app/id6475263425" target="_blank">
          <img class="badge" src="./assets/app-store-badge.png" alt="可以骰骰手機 App App Store 下載圖示" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.benleungcreative.sicsiccontroller" target="_blank">
          <img class="badge" src="./assets/google-play-badge.png" alt="可以骰骰手機 App Google Play 下載圖示" />
        </a>
      </div>
      <br />
      <div class="category-container">
        <div class="cat-title">TV</div>
        <a href="https://play.google.com/store/apps/details?id=com.benleungcreative.bigsmall" target="_blank">
          <img class="badge" src="./assets/google-play-badge.png" alt="可以骰骰 TV App Google Play 下載圖示" />
        </a>
      </div>
    </div>

    <br />
    <div class="app-container">
      <a href="https://fsc.sicsic.xyz" target="_blank">
        <div>
          <div class="app-icon fsc"></div><br />
          <div>魚蝦蟹</div>
        </div>
      </a>
      <br /><br />
      <div>
        <a href="https://apps.apple.com/us/app/id6475633351" target="_blank">
          <img class="badge" src="./assets/app-store-badge.png" alt="魚蝦蟹 App Store 下載圖示" /><br />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.benleungcreative.fsc" target="_blank">
          <img class="badge" src="./assets/google-play-badge.png" alt="魚蝦蟹 Google Play 下載圖示" />
        </a>
      </div>
    </div>
    <a href="https://instagram.com/sicsic.xyz" target="_blank">
      <div class="ig">
        Follow us on <i class="fa-brands fa-instagram"></i>
      </div>
    </a>
  </div>
  <DisclaimerComp />
</template>

<script>
import DisclaimerComp from './components/DisclaimerComp'

export default {
  name: 'sicsicXyzHomepage',
  components: {
    DisclaimerComp
  }
}
</script>

<style> html,
 body {
   height: 100%;
   padding: 0;
   margin: 0;
   background-color: white;
   color: #2c3e50;
 }

 #app {
   height: 100%;
 }

 #app {
   height: 100%;
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
 }

 a {
   text-decoration: none;
   color: #2c3e50;
 }
</style>

<style scoped>
a {
  display: block;
  width: 100%;
}

.two-app-container {
  padding-top: 10px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.separator {
  width: 128px;
}

.app-container {
  background-color: white;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  width: 70%;
  padding-top: 30px;
  padding-bottom: 30px;
}

div {
  display: inline-block;
}

.app-icon {
  width: 128px;
  height: 128px;
  background-color: white;
  border-radius: 24px;
  border: 1px solid #eeeeee;
  background-position: center;
  background-size: 80px;
  background-repeat: no-repeat;
}

.app-icon.big-small {
  background-image: url('./assets/dice.png');
}

.app-icon.fsc {
  background-image: url('./assets/calabash.png');
}

.badge {
  width: 120px;
}

.category-container {
  display: inline-block;
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;
}

.badge {
  margin: 0 10px;
}

.cat-title {
  background: black;
  margin-bottom: 5px;
}

.icon-td {
  padding: 0 50px;
}

.cat-title {
  display: block;
  color: white;
  padding: 4px 0px;
}

.ig {
  padding: 32px;
}

@media screen and (max-width:768px) {
  .app-container {
    width: 90%;
  }
}
</style>
